<template>
  <div class="row biomas-map-mobile">
    <div class="col-12 mt-5 mb-1">
      <img
        id="brasil"
        :src="this.image"
        usemap="#image-map-mob"
        class="mt-5 img-fluid"
      />
      <map name="image-map-mob">
        <area
          class="amazon"
          @click="click('amazonia-selecionado', 'AMAZONIA')"
          target=""
          alt="Amazônia"
          title="Amazônia"
          coords="50,199,88,183,101,208,109,215,143,228,152,236,158,243,174,263,179,255,192,257,199,251,203,238,196,235,186,247,175,248,167,236,165,219,168,207,175,205,183,210,190,219,199,228,212,215,218,221,229,228,243,230,257,227,262,223,260,211,267,207,260,201,262,195,271,190,276,194,291,169,296,179,300,169,302,158,308,146,305,138,309,133,331,138,335,132,337,125,346,118,351,110,357,103,359,97,353,96,336,100,328,81,323,76,317,73,306,75,279,89,282,78,286,71,286,65,276,63,269,64,262,73,248,83,245,75,249,65,258,59,262,54,266,48,266,36,261,24,258,15,248,20,244,31,232,32,212,35,194,37,162,46,150,28,152,1,130,11,123,14,108,12,110,24,113,32,109,42,88,53,76,47,69,40,59,38,51,40,48,46,46,54,44,112,8,123,4,141,1,156,5,168,16,181,25,183,36,186,42,191"
          shape="poly"
        />
        <area
          class="pampa"
          @click="click('pampa-selecionado', 'PAMPA')"
          target=""
          alt="Pampa"
          title="Pampa"
          coords="237,422,227,439,239,450,246,454,261,465,280,478,302,432,293,437,284,437,277,432,273,423,258,423,264,423"
          shape="poly"
        />
        <area
          class="mata"
          @click="click('mata-atlantica-selecionado', 'MATA_ATLANTICA')"
          target=""
          alt="Mata Atlântica"
          title="Mata Atlântica"
          coords="445,132,453,149,453,158,449,167,445,176,439,186,431,196,418,211,411,223,407,238,406,263,406,281,402,296,398,317,391,337,381,348,372,353,355,355,335,362,316,376,311,384,311,402,311,411,302,429,292,434,284,434,278,429,274,422,267,421,239,420,251,407,254,390,248,377,239,364,224,356,212,348,199,333,213,334,214,341,226,332,233,327,245,334,261,314,269,300,272,289,287,285,294,283,294,289,287,293,279,295,278,302,288,305,296,318,288,322,292,333,267,335,276,340,286,345,284,352,276,358,282,359,289,354,296,354,302,341,311,330,313,321,319,319,333,323,333,312,348,309,354,306,353,298,355,290,364,288,371,285,372,276,368,267,374,260,368,248,385,242,392,236,404,215,404,206,411,209,420,203,426,189,432,185,436,179,435,172,441,169,446,160,447,153"
          shape="poly"
        />
        <area
          class="caatinga"
          @click="click('caatinga-selecionado', 'CAATINGA')"
          target=""
          alt="Caatinga"
          title="Caatinga"
          coords="442,131,446,154,444,161,440,169,434,173,435,179,432,187,424,189,420,202,411,208,404,204,400,216,391,235,384,240,366,246,354,260,359,239,361,225,361,188,354,182,361,174,360,167,362,155,358,149,366,147,361,138,367,133,371,126,369,118,385,111,394,101,415,118"
          shape="poly"
        />
        <area
          class="cerrado"
          @click="click('cerrado-selecionado', 'CERRADO')"
          target=""
          alt="Cerrado"
          title="Cerrado"
          coords="367,118,382,109,392,100,360,94,359,104,351,112,346,119,338,126,335,136,329,140,309,134,306,140,309,145,306,151,304,159,300,176,295,181,291,172,278,194,271,192,264,195,261,201,268,206,261,212,264,224,244,232,228,229,216,222,212,216,199,228,182,211,174,206,168,209,166,219,167,236,175,247,185,246,195,235,204,236,202,245,199,254,196,262,205,256,211,259,221,262,224,291,226,296,214,312,207,312,200,305,198,311,200,321,199,329,214,333,221,333,229,327,245,331,259,316,268,300,269,288,294,281,298,287,294,293,285,296,279,301,288,302,298,315,291,324,293,334,269,338,287,345,278,358,288,353,295,352,309,328,313,319,319,316,331,321,333,309,347,308,353,305,348,295,354,291,368,284,368,276,366,266,372,260,366,248,353,262,358,237,360,215,359,189,352,182,360,173,356,166,359,154,355,147,364,146,359,136,366,132,369,126"
          shape="poly"
        />
        <area
          class="pantanal"
          @click="click('pantanal-selecionado', 'PANTANAL')"
          target=""
          alt="Pantanal"
          title="Pantanal"
          coords="175,263,185,269,194,280,195,287,194,300,193,314,196,327,196,307,201,301,207,310,212,305,213,311,224,297,221,290,220,264,205,258,196,265,193,258,179,256"
          shape="poly"
        />
        <area
          class="marinho"
          @click="click('marinho-selecionado', 'SISTEMA_COSTEIRO_MARINHO')"
          target=""
          alt="Sistema Costeiro-Marinho"
          title="Sistema Costeiro-Marinho"
          coords="282,477,304,430,313,412,312,384,324,371,336,363,357,355,372,354,381,349,392,338,408,281,409,239,415,219,420,209,432,196,443,181,455,158,454,149,446,131,440,129,416,117,396,101,360,92,355,95,337,99,329,79,319,72,281,87,284,77,287,71,288,65,276,61,269,62,261,72,250,80,247,75,250,67,258,61,262,56,268,50,268,37,259,14,260,1,479,2,478,478"
          shape="poly"
        />
      </map>
    </div>
    <div class="col-12 mb-3">
        <div class="graphic-button" style="margin:0;">
            <button class="fill active" @click="openTableRegister()">ver registros</button>
            <button class="fill" @click="clearFilter()">limpar filtro</button>
        </div>
    </div>
    <div class="col-12">
        <GraphicMaps :endpoint="endpoint" :data="data" />
    </div>
  </div>
</template>

<script>
import GraphicMaps from '@/mobile/components/GraphicMaps.vue'
import ImageMap from 'image-map'
import barramento from '@/barramento'

export default {
  components: {
      GraphicMaps
  },
  props: ['visible','data'],
  data() {
    return {
      endpoint: "",
      image: "img/mapa/brasil.png"
    };
  },
  watch: {
      visible(){
          if (this.visible == true){
              this.$nextTick(function() {
                  setTimeout(function () { ImageMap('img[usemap]')}, 1000)
              });
          }
      }
  },
  methods: {
    clearFilter() {
        this.image='img/mapa/brasil.png';
        this.endpoint = ''
    },
    click(map, name) {
        if(map == this.image) {
            this.image='img/mapa/brasil.png';
            this.endpoint = ''
        } else {
            this.image = 'img/mapa/' + map + '.png';
            this.endpoint = name
        }
    },
    openTableRegister() {

        let name = this.endpoint
        if (name == 'AMAZONIA') {
            //ARRUMAR ROTA
            barramento.$emit('openModalR', {'query': "/search?biomaIds=20", 'items': {'biomaIds': [20]}, 'filter': 'AMAZONIA'})
        } else if (name == 'PAMPA') {
            //ARRUMAR ROTA
            barramento.$emit('openModalR', {'query': "/search?biomaIds=25", 'items': {'biomaIds': [25]}, 'filter': 'PAMPA'})
        } else if (name == 'MATA_ATLANTICA') {
            //ARRUMAR ROTA
            barramento.$emit('openModalR', {'query': "/search?biomaIds=24", 'items': {'biomaIds': [24]}, 'filter': 'MATA ATLÂNTICA'})
        } else if (name == 'CAATINGA') {
            //ARRUMAR ROTA
            barramento.$emit('openModalR', {'query': "/search?biomaIds=21", 'items': {'biomaIds': [21]}, 'filter': 'CAATINGA'})
        } else if (name == 'CERRADO') {
            //ARRUMAR ROTA
            barramento.$emit('openModalR', {'query': "/search?biomaIds=22", 'items': {'biomaIds': [22]}, 'filter': 'CERRADO'})
        } else if (name == 'PANTANAL') {
            //ARRUMAR ROTA
            barramento.$emit('openModalR', {'query': "/search?biomaIds=26", 'items': {'biomaIds': [26]}, 'filter': 'PANTANAL'})
        }  else if (name == 'SISTEMA_COSTEIRO_MARINHO') {
            //ARRUMAR ROTA
            barramento.$emit('openModalR', {'query': "/search?biomaIds=23", 'items': {'biomaIds': [23]}, 'filter': 'SISTEMA COSTEIRO MARINHO'})
        }
    }
  },
};
</script>
